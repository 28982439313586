import { createSlice } from '@reduxjs/toolkit';

export const authenticateSlice = createSlice({
  name: 'authenticated',
  initialState: {
    authenticated: false
  },
  reducers: {
    login: (state) => {
      state.authenticated = true;
    },
    logout: (state) => {
      state.authenticated = false;
    }
  }
});

export const { login, logout } = authenticateSlice.actions;
export default authenticateSlice.reducer;
