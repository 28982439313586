exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-documentations-tsx": () => import("./../../../src/pages/documentations.tsx" /* webpackChunkName: "component---src-pages-documentations-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-tsx": () => import("./../../../src/pages/integrations.tsx" /* webpackChunkName: "component---src-pages-integrations-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-profiles-tsx": () => import("./../../../src/pages/profiles.tsx" /* webpackChunkName: "component---src-pages-profiles-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-usecases-tsx": () => import("./../../../src/pages/usecases.tsx" /* webpackChunkName: "component---src-pages-usecases-tsx" */),
  "component---src-templates-feature-template-tsx": () => import("./../../../src/templates/featureTemplate.tsx" /* webpackChunkName: "component---src-templates-feature-template-tsx" */),
  "component---src-templates-integration-template-tsx": () => import("./../../../src/templates/integrationTemplate.tsx" /* webpackChunkName: "component---src-templates-integration-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-profile-template-tsx": () => import("./../../../src/templates/ProfileTemplate.tsx" /* webpackChunkName: "component---src-templates-profile-template-tsx" */),
  "component---src-templates-tool-template-tsx": () => import("./../../../src/templates/ToolTemplate.tsx" /* webpackChunkName: "component---src-templates-tool-template-tsx" */),
  "component---src-templates-use-case-template-tsx": () => import("./../../../src/templates/UseCaseTemplate.tsx" /* webpackChunkName: "component---src-templates-use-case-template-tsx" */)
}

