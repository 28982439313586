import "./src/styles/global.css"

import React from "react";
import { Provider } from "react-redux";
import { store } from "./src/redux/store";

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  console.log(`onPreRouteUpdate`);
  console.log(`new pathname : ${location.pathname}`);
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log(`onRouteUpdate`);
  console.log(`new pathname : ${location.pathname}`);
  // console.log(`old pathname : ${prevLocation.pathname}`);
}

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}